import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import {
  container,
  pillarTag,
  cardContainer,
  leftBox,
  rightBox,
  title,
  subTitle,
  tagName,
  featuredArticles,
  articlesContainer,
  articleCard,
  cardContent,
  cardImage,
  cardTag,
  cardTitle,
  buttonPillarTag,
  cardDescription,
} from "../styles/moneySection.module.css"

import firstImg from "../images/landing-page/the-money-lessons-these-women-leaders.jpeg"
import secondImg from "../images/landing-page/shop-the-farmers-market-like-a-regular.jpeg"
import thirdImg from "../images/landing-page/the-secret-of-the-solid-am-pm-routine.jpeg"

const WellBeingSection = () => {
  const articles = [
    {
      image: firstImg,
      title: "The Money Lessons these Women Leaders Took to Heart",
      link: "the-money-lessons-these-women-leaders",
      tag: "managing money",
    },
    {
      image: secondImg,
      title: "Shop the Farmers Market Like a Regular",
      link: "shop-the-farmers-market-like-a-regular",
      tag: "food",
    },
    {
      image: thirdImg,
      title: "The Secret to a Solid AM/PM Routine? It’s Printables",
      link: "the-secret-to-the-solid-am-pm-routine",
      tag: "productivity",
    },
  ]
  return (
    <>
      <section className={container}>
        <h2 className={pillarTag}>Well-being</h2>

        <div className={cardContainer}>
          <div className={leftBox}>
            <Link to="/time-famine-real" itemProp="url">
              {" "}
              <StaticImage src="../images/landing-page/time-famine-real.jpg" />{" "}
            </Link>
          </div>
          <div className={rightBox}>
            {/* tag */}
            <div className={tagName}>
              {/* <Link to="/" itemProp="url" className={tagName}> */}
              productivity
              {/* </Link> */}
            </div>
            <h2 className={title}>
              <Link to="/time-famine-real" itemProp="url">
                Time Famine Is Real. Here’s How to Conquer It
              </Link>
            </h2>
            <p className={subTitle}>
              Experts Laura Vanderkam, Julie Morgenstern, and Leslie A. Perlow
              reveal better ways to manage the hours in your day.
            </p>
          </div>
        </div>
      </section>
      <section className={featuredArticles}>
        <div className={articlesContainer}>
          {articles.map((post, index) => (
            <div className={articleCard} key={index}>
              <Link to={`/${post.link}`} itemProp="url">
                <div className={cardImage}>
                  <img src={post.image} alt={post.title} />
                </div>
              </Link>
              <div className={cardContent}>
                <div className={cardTag}>
                  <div className={tagName}>
                    {/* <Link to="/" itemProp="url" className={tagName}> */}
                    {post.tag}
                    {/* </Link> */}
                  </div>
                </div>
                <div className={cardTitle}>
                  <h3 className={title}>
                    <Link to={`/${post.link}`} itemProp="url">
                      {post.title}
                    </Link>
                  </h3>
                  <p className={cardDescription}>
                    <Link to={`/${post.link}`} itemProp="url">
                      {post.subTitle}
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      {/* <div className={buttonPillarTag}>
        <h3 className={title}>
          <Link to="/money">More in money</Link>
        </h3>
      </div> */}
    </>
  )
}

export default WellBeingSection
