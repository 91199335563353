import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import {
  container,
  pillarTag,
  cardContainer,
  leftBox,
  rightBox,
  title,
  subTitle,
  tagName,
  featuredArticles,
  articlesContainer,
  articleCard,
  cardContent,
  cardImage,
  cardTag,
  cardTitle,
  buttonPillarTag,
  cardDescription,
} from "../styles/moneySection.module.css"

import teachKidsImg from "../images/landing-page/you-can-teach-kids-about-money.jpg"
import practicalImg from "../images/landing-page/the-practical-and-emotional-sides-of-home-buying.png"
import virtualImg from "../images/landing-page/what-virtual-tours-can.jpg"

const MilestonesSection = () => {
  const articles = [
    {
      image: teachKidsImg,
      title: "You Can Teach Kids About Money — Even if it Stresses You Out",
      link: "you-can-teach-kids-about-money",
      tag: "managing money",
    },
    {
      image: practicalImg,
      title: "The Practical and Emotional Sides of Home Buying",
      link: "the-practical-and-emotional-sides-of-home-buying",
      tag: "home",
    },
    {
      image: virtualImg,
      title: "What Virtual Tours Can — and Can't — Tell You About a Home",
      link: "what-virtual-tours-can",
      tag: "smart moves",
    },
  ]
  return (
    <>
      <section className={container}>
        <h2 className={pillarTag}>Milestones</h2>

        <div className={cardContainer}>
          <div className={leftBox}>
            <Link to="/let-your-senses-guide-your-home" itemProp="url">
              {" "}
              <StaticImage src="../images/landing-page/let-your-senses-guide-your-home.jpg" />{" "}
            </Link>
          </div>
          <div className={rightBox}>
            {/* tag */}
            <div className={tagName}>
              {/* <Link to="/" itemProp="url" className={tagName}> */}
              Career
              {/* </Link> */}
            </div>
            <h2 className={title}>
              <Link to="/let-your-senses-guide-your-home" itemProp="url">
                Let Your Senses Guide Your Home-Office Makeover
              </Link>
            </h2>
            <p className={subTitle}>
              Check out this five-senses guide to help design a mood boosting —
              and productive — work from home space.
            </p>
          </div>
        </div>
      </section>
      <section className={featuredArticles}>
        <div className={articlesContainer}>
          {articles.map((post, index) => (
            <div className={articleCard} key={index}>
              <Link to={`/${post.link}`} itemProp="url">
                <div className={cardImage}>
                  <img src={post.image} alt={post.title} />
                </div>
              </Link>
              <div className={cardContent}>
                <div className={cardTag}>
                  <div className={tagName}>
                    {/* <Link to="/" itemProp="url" className={tagName}> */}
                    {post.tag}
                    {/* </Link> */}
                  </div>
                </div>
                <div className={cardTitle}>
                  <h3 className={title}>
                    <Link to={`/${post.link}`} itemProp="url">
                      {post.title}
                    </Link>
                  </h3>
                  <p className={cardDescription}>
                    <Link to={`/${post.link}`} itemProp="url">
                      {post.subTitle}
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      {/* <div className={buttonPillarTag}>
        <h3 className={title}>
          <Link to="/money">More in money</Link>
        </h3>
      </div> */}
    </>
  )
}

export default MilestonesSection
