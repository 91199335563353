// extracted by mini-css-extract-plugin
export var container = "moneySection-module--container--1ZBg6";
export var pillarTag = "moneySection-module--pillarTag--1O6A_";
export var cardContainer = "moneySection-module--cardContainer--1UaGl";
export var leftBox = "moneySection-module--leftBox--36zgp";
export var rightBox = "moneySection-module--rightBox--2CBmY";
export var title = "moneySection-module--title--3ai7G";
export var subTitle = "moneySection-module--subTitle--1nTcf";
export var tagName = "moneySection-module--tagName--SDF75";
export var articlesContainer = "moneySection-module--articlesContainer--JH2v2";
export var articleCard = "moneySection-module--articleCard--dvSxf";
export var cardContent = "moneySection-module--cardContent--1SETd";
export var cardImage = "moneySection-module--cardImage--po297";
export var cardTag = "moneySection-module--cardTag--1ZKeT";
export var cardTitle = "moneySection-module--cardTitle--2NlGT";
export var buttonPillarTag = "moneySection-module--buttonPillarTag--1ZLqX";