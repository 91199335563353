import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import {
  container,
  pillarTag,
  cardContainer,
  leftBox,
  rightBox,
  title,
  subTitle,
  tagName,
  featuredArticles,
  articlesContainer,
  articleCard,
  cardContent,
  cardImage,
  cardTag,
  cardTitle,
  buttonPillarTag,
  cardDescription,
} from "../styles/moneySection.module.css"

import traditionImg from "../images/landing-page/tradition-belonging-identity-and-what-lies-between.jpg"
import thePowerImg from "../images/landing-page/the-power-and-importance-of-name-and-identity.jpg"
import femaleImg from "../images/landing-page/female-empowerment-on-the-golf-course.png"

const CultureSection = () => {
  const articles = [
    {
      image: traditionImg,
      title: "Tradition, Belonging, Identity and What Lies Between",
      link: "tradition-belonging-identity-and-what-lies-between",
      tag: "community",
    },
    {
      image: thePowerImg,
      title: "The Power and Importance of Name and Identity",
      link: "the-power-and-importance-of-name-and-identity",
      tag: "community",
    },
    {
      image: femaleImg,
      title: "Female Empowerment on the Golf Course",
      link: "female-empowerment-on-the-golf-course",
      tag: "sports",
    },
  ]
  return (
    <>
      <section className={container}>
        <h2 className={pillarTag}>Culture</h2>

        <div className={cardContainer}>
          <div className={leftBox}>
            <Link to="/on-my-terms-with-Caroline-Wozniacki" itemProp="url">
              {" "}
              <StaticImage
                src="../images/landing-page/on-my-terms-with-Caroline-Wozniacki.png"
                alt="Caroline Wozniacki"
              />{" "}
            </Link>
          </div>
          <div className={rightBox}>
            {/* tag */}
            <div className={tagName}>
              {/* <Link to="/" itemProp="url" className={tagName}> */}
              Sports
              {/* </Link> */}
            </div>
            <h2 className={title}>
              <Link to="/on-my-terms-with-Caroline-Wozniacki" itemProp="url">
                On My Terms with Caroline Wozniacki
              </Link>
            </h2>
            <p className={subTitle}>
              With 30 WTA singles titles, Caroline Wozniacki shares career and
              life lessons in preparation for her next match: motherhood.
            </p>
          </div>
        </div>
      </section>
      <section className={featuredArticles}>
        <div className={articlesContainer}>
          {articles.map((post, index) => (
            <div className={articleCard} key={index}>
              <Link to={`/${post.link}`} itemProp="url">
                <div className={cardImage}>
                  <img src={post.image} alt={post.title} />
                </div>
              </Link>
              <div className={cardContent}>
                <div className={cardTag}>
                  <div className={tagName}>
                    {/* <Link to="/" itemProp="url" > */}
                    {post.tag}
                    {/* </Link>
                     */}
                  </div>
                </div>
                <div className={cardTitle}>
                  <h3 className={title}>
                    <Link to={`/${post.link}`} itemProp="url">
                      {post.title}
                    </Link>
                  </h3>
                  <p className={cardDescription}>
                    <Link to={`/${post.link}`} itemProp="url">
                      {post.subTitle}
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      {/* <div className={buttonPillarTag}>
        <h3 className={title}>
          <Link to="/money">More in money</Link>
        </h3>
      </div> */}
    </>
  )
}

export default CultureSection
