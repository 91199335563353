import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { Carousel } from "react-responsive-carousel"
import { containerSlider, heroContent } from "../styles/heroCarousel.module.css"

import first from "../images/hero/recipe-for-revival-with-gregory-porter.png"
import second from "../images/hero/your-portfolio-now-greener.jpeg"
import third from "../images/hero/3-chef-on-what-it-takes-to-nourish.jpeg"
import fourth from "../images/hero/how-black-women-builders-voices.jpeg"
import fifth from "../images/hero/scams-are-on-the-rise-in-2021.jpeg"

const Heropage = () => {
  const posts = [
    {
      image: first,
      title: "Recipe for Revival with Gregory Porter",
      tag: "food",
      link: "recipe-for-revival-with-gregory-porter",
    },
    {
      image: second,
      title: "Your Portfolio, Now Greener: The Emergence of Green Bonds",
      tag: "investing",
      link: "your-portfolio-now-greener",
    },
    {
      image: third,
      title: "3 Chefs on What it Takes to Nourish a Community",
      tag: "community",
      link: "3-chef-on-what-it-takes-to-nourish",
    },
    {
      image: fourth,
      title: "How Black Women Builders’ Voices Have Championed Communities",
      tag: "community",
      link: "how-black-women-builders-voices",
    },
    {
      image: fifth,
      title: "Scams Are on the Rise in 2021, Here’s What to Watch Out For",
      tag: "finance 101",
      link: "scams-are-on-the-rise-in-2021",
    },
  ]

  return (
    <>
      <Carousel showStatus={false} showThumbs={false}>
        {posts.map((post, index) => {
          const title = post.title
          const image = post.image
          const tag = post.tag
          const link = post.link

          return (
            <div key={index}>
              <div className={containerSlider}>
                <Link to={`/${link}`}>
                  <img src={image} alt={title} />
                </Link>
                <div className={heroContent}>
                  <h4>{tag}</h4>
                  <h1>
                    <Link to={`/${link}`}>{title}</Link>
                  </h1>
                </div>
              </div>
            </div>
          )
        })}
      </Carousel>
    </>
  )
}

export default Heropage
