// extracted by mini-css-extract-plugin
export var container = "editorsPicks-module--container--1m0cM";
export var pillarTag = "editorsPicks-module--pillarTag--XPezd";
export var cardContainer = "editorsPicks-module--cardContainer--nXRev";
export var leftBox = "editorsPicks-module--leftBox--JbSXx";
export var rightBox = "editorsPicks-module--rightBox--1ghBF";
export var title = "editorsPicks-module--title--nUxOL";
export var subTitle = "editorsPicks-module--subTitle--2G1M1";
export var tagName = "editorsPicks-module--tagName--38qEs";
export var articlesContainer = "editorsPicks-module--articlesContainer--2oT3a";
export var articleCard = "editorsPicks-module--articleCard--29mEG";
export var cardImage = "editorsPicks-module--cardImage--HrjYg";
export var cardTag = "editorsPicks-module--cardTag--28PkV";
export var cardTitle = "editorsPicks-module--cardTitle--Zr95a";
export var cardDescription = "editorsPicks-module--cardDescription--_hOgO";