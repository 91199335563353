import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import {
  container,
  title,
  tagName,
  articlesContainer,
  articleCard,
  cardContent,
  cardImage,
  cardTag,
  cardTitle,
  cardDescription,
} from "../styles/editorsPicks.module.css"

import breakingDownImg from "../images/editors-picks/breaking-down-barries-in-healthcare.png"
import perspectiveImg from "../images/editors-picks/perspective-on-transgender-identity.png"
import lookingImproveImg from "../images/editors-picks/looking-to-improve-company-culture.jpeg"

const EditorsPicks = () => {
  const articles = [
    {
      image: breakingDownImg,
      title: "Breaking Down Barriers in Healthcare and Venture Capital",
      subTitle:
        "When does the path to startup funding resemble a trip to the doctor’s office?",
      link: "breaking-down-barries-in-healthcare",
      tag: "community",
    },
    {
      image: perspectiveImg,
      title: "Perspectives on Transgender Identity and Representation",
      subTitle:
        "Go behind-the-scenes of a recent Citi campaign to learn about the power and importance of identity and names for the transgender and non-binary community.",
      link: "perspective-on-transgender-identity",
      tag: "community",
    },
    {
      image: lookingImproveImg,
      title: "Looking to Improve Company Culture? Prioritize Inclusivity",
      subTitle:
        "The advocacy of trailblazing employees is the force behind policy change for inclusion for LGBTQ+ people in the workplace.",
      link: "looking-to-improve-company-culture",
      tag: "career",
    },
  ]
  return (
    <>
      <section className={container}>
        <h2>Editor's Picks</h2>
        <div className={articlesContainer}>
          {articles.map((post, index) => (
            <div className={articleCard} key={index}>
              <Link to={`/${post.link}`} itemProp="url">
                <div className={cardImage}>
                  <img src={post.image} alt={post.title} />
                </div>
              </Link>
              <div className={cardContent}>
                <div className={cardTag}>
                  <Link to="/" itemProp="url" className={tagName}>
                    {post.tag}
                  </Link>
                  <div className={cardTitle}>
                    <h3 className={title}>
                      <Link to={`/${post.link}`} itemProp="url">
                        {post.title}
                      </Link>
                    </h3>
                    <p className={cardDescription}>
                      <Link to={`/${post.link}`} itemProp="url">
                        {post.subTitle}
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  )
}

export default EditorsPicks
