import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import {
  container,
  pillarTag,
  cardContainer,
  leftBox,
  rightBox,
  title,
  subTitle,
  tagName,
  featuredArticles,
  articlesContainer,
  articleCard,
  cardContent,
  cardImage,
  cardTag,
  cardTitle,
  buttonPillarTag,
  cardDescription,
} from "../styles/moneySection.module.css"
import firstImg from "../images/landing-page/look-to-this-japanes-method.jpg"
import secondImg from "../images/landing-page/how-to-teach-kids-about-money.jpg"
import thirdImg from "../images/landing-page/bootstrap-your-wallet.jpg"

const MoneySection = () => {
  const articles = [
    {
      image: firstImg,
      title: "Look to This Japanese Method for a New Boost to Your Budget",
      link: "look-to-this-japanes-method",
      tag: "productivity",
    },
    {
      image: secondImg,
      title: "How to Teach Kids About Money — at Any Age",
      link: "how-to-teach-kids-about-money",
      tag: "financial education",
    },
    {
      image: thirdImg,
      title: "Bootstrap Your Wallet — and Your Life",
      link: "bootstrap-your-wallet-and-your-life",
      tag: "managing money",
    },
  ]
  return (
    <>
      <section className={container}>
        <h2 className={pillarTag}>Money</h2>

        <div className={cardContainer}>
          <div className={leftBox}>
            <Link
              to="/tips-to-create-your-family-financial-plan"
              itemProp="url"
            >
              <StaticImage src="../images/landing-page/tips-to-create-your-family-financial-plan.jpg" />
            </Link>
          </div>
          <div className={rightBox}>
            {/* tag */}
            <div className={tagName}>
              {/* <Link to="/" itemProp="url" > */}
              Family
              {/* </Link> */}
            </div>
            <h2 className={title}>
              <Link
                to="/tips-to-create-your-family-financial-plan"
                itemProp="url"
              >
                Tips to Create Your Family's Financial Plan
              </Link>
            </h2>
            <p className={subTitle}>
              Getting the whole family to agree on a long-range financial plan
              is possible when you set goals that everyone can get behind.
            </p>
          </div>
        </div>
      </section>
      <section className={featuredArticles}>
        <div className={articlesContainer}>
          {articles.map((post, index) => (
            <div className={articleCard} key={index}>
              <Link to={`/${post.link}`} itemProp="url">
                <div className={cardImage}>
                  <img src={post.image} alt={post.title} />
                </div>
              </Link>
              <div className={cardContent}>
                <div className={cardTag}>
                  <div className={tagName}>
                    {/* <Link to="/" itemProp="url" className={tagName}> */}
                    {post.tag}
                    {/* </Link> */}
                  </div>
                </div>
                <div className={cardTitle}>
                  <h3 className={title}>
                    <Link to={`/${post.link}`} itemProp="url">
                      {post.title}
                    </Link>
                  </h3>
                  <p className={cardDescription}>
                    <Link to={`/${post.link}`} itemProp="url">
                      {post.subTitle}
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      {/* <div className={buttonPillarTag}>
        <h3 className={title}>
          <Link to="/money">More in money</Link>
        </h3>
      </div> */}
    </>
  )
}

export default MoneySection
