import * as React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Heropage from "../components/Herocarousel"
import MoneySection from "../components/moneySection"
import EditorsPicks from "../components/editorsPicks"
import CultureSection from "../components/CultureSection"
import MilestonesSection from "../components/MilestonesSection"
import WellBeingSection from "../components/wellBeingSection"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="All posts" />
      <Heropage articles={posts} />
      <EditorsPicks />
      <MoneySection />
      <CultureSection />
      <MilestonesSection />
      <WellBeingSection />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          featuredImage {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
